import React, { useState } from "react"
import Img from "gatsby-image"
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    } from 'reactstrap';
import { css } from "@emotion/core"

export default function SuperCarousel( props ) {
    const [activeIndex, setActiveIndex] = useState(0);
    const slides = props.Items.map((item) => {
        return (
            <CarouselItem>
                <a href={item.link} title={(item.link) ? props.LinkText : null} rel="noreferrer" target="_blank">
                    <Img fluid={item.photo} css={css`
                        height: 75vh;
                        width: auto;
                        @media screen and (max-width: 1280px) {
                            height: 100vh;
                        }
                    `} loading="eager"/>
                    <CarouselCaption captionText={item.text} captionHeader={item.header} />
                </a>
            </CarouselItem>
        );
    })
    const slidesLength = slides.length;
    const next = () => {
        const nextIndex = activeIndex === slidesLength - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        const nextIndex = activeIndex === 0 ? slidesLength - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        setActiveIndex(newIndex);
    }

    return (
        <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            interval={7000}
            style={{ height: "100%"}}
        >
            <CarouselIndicators items={props.Items} activeIndex={activeIndex} onClickHandler={goToIndex} />
            {slides}
            <CarouselControl direction="prev" directionText=" " onClickHandler={previous} />
            <CarouselControl direction="next" directionText=" " onClickHandler={next} />
        </Carousel>
    )
}