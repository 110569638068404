import React from "react"
import { graphql } from "gatsby"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import styled from "styled-components"

import SuperCarousel from "../../components/carousel"
import RandomGallery from "../../components/gallery"
import { Layout } from "../../components/layout"
import { GridTwo, HorSep } from "../../components/grids"
import { H4UppercaseGrey } from "../../components/headings"

const TextDiv = styled.div`
padding: 80px;
text-align: right;
padding-bottom: 40px;
@media screen and (max-width: 813px) {
    text-align: right;
    padding: 30px;
    font-size: 0.9rem;
    max-height: fit-content;
}
`

export default function References({ data }) {
    const { allContentfulReference, references } = data;
    const options = {
        renderNode: {
            [BLOCKS.HEADING_3]: (node, children) => <H4UppercaseGrey>{children}</H4UppercaseGrey>,
        }
    }

    let cmsItems = [];
    allContentfulReference.nodes.map((item) => {
        let descr = "";
        item.photos.map((photo) => {
            if (photo.description.length === 0) {
                descr = item.description.description;
            } else {
                descr = photo.description;
            }
            cmsItems.push({
                "photo": photo.fluid,
                "text": descr,
                "header": item.name,
                "link": item.linkTarget
            });
        })
    })
    const onlyImages = cmsItems.map((item) => {
        return item.photo;
    })

    return (
        <Layout title="References" lang="en" actSite="references/">
            <HorSep height="150px"/>
            <GridTwo 
                leftFlex="33"
                rightFlex="66"
                left={
                    <TextDiv>
                        {documentToReactComponents(references.content.json, options)}
                    </TextDiv>
                }
                right={
                    <SuperCarousel Items={cmsItems} LinkText={"More pictures ..."} />
                }
            />
            <RandomGallery Items={onlyImages} />
        </Layout>
    )
}

export const query = graphql`
    query {
        allContentfulReference(filter: {node_locale: {eq: "en"}}, sort: {fields: sortId}) {
            nodes {
                photos {
                    fluid(maxWidth: 1200, quality: 100) {
                        src
                        ...GatsbyContentfulFluid_withWebp
                    }
                    description
                }
                name
                linkTarget
                description {
                    description
                }
                node_locale
            }
        }
        references: contentfulTextOnly(title: {eq: "References"}, node_locale: {eq: "en"}) {
            content {
                json
            }
        }
    }
`
