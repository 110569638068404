import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { css } from "@emotion/core"

export default function RandomGallery( props ) {
    const Columns = 8;
    const Rows = 30;

    const Gallery = styled.div`
        display: grid;
        grid-template-columns: repeat(${Columns}, 1fr);
        grid-template-rows: repeat(${Rows}, 5vw);
        grid-gap: 15px;
        transition: all 1s ease;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        img:hover {
            transform: scale(1.1);
        }

        @media screen and (max-width: 1113px) {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;

            div {
                grid-column: auto;
                grid-row: auto;
            }
        }
    `

    function shuffle(array) {
        let currentIndex = array.length;
        let temporaryValue = 0;
        let randomIndex = 0;

        // While there remain elements to shuffle...
        while (currentIndex !== 0) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;
    }

    function getRandomInt(min, max) {
        let minInt = Math.ceil(min);
        let maxInt = Math.floor(max);
        // The maximum is exclusive and the minimum is inclusive
        return Math.floor(Math.random() * (maxInt - minInt) + minInt);
    }

    function range(size, startAt = 0) {
        return [...Array(size).keys()].map(idx => idx + startAt);
    }

    const ColStart_LuT = range(Columns, 1);
    const ColEnd_LuT = range(Columns, 2);
    const RowStart_LuT = range(Rows, 1);
    const RowEnd_LuT = range(Rows, 2);
    let galleryGrid = new Array(Rows);
    for (let i = 0; i < Rows; i++) {
        galleryGrid[i] = new Array(Columns);
    }
    for (let r = 0; r < Rows; r++) {
        for (let c = 0; c < Columns; c++) {
            galleryGrid[r][c] = '-';
        }
    }
    const GalleryContent = shuffle(props.Items).map((item) => {
        let xstart = -1;
        let xmax = -1;
        let ystart = -1;
        let ymax = -1;
        for (let r = 0; r < Rows; r++) {
            for (let c = 0; c < Columns; c++) {
                if ((xstart == -1) && (galleryGrid[r][c] == '-')) {
                    xstart = c;
                } else {
                    if ((xstart != -1) && (galleryGrid[r][c] != '-')) {
                        xmax = c - 1;
                        break;
                    }
                }
            }
            if ((xmax == -1) && (xstart != -1)) {
                xmax = Columns - 1;
            }
            if (xmax != -1) {
                break;
            }
        }
        for (let c = xstart; c <= xmax; c++) {
            for (let r = 0; r < Rows; r++) {
                if ((ystart == -1) && (galleryGrid[r][c] == '-')) {
                    ystart = r;
                } else {
                    if ((ystart != -1) && (galleryGrid[r][c] != '-')) {
                        ymax = r - 1;
                        break;
                    }
                }
            }
            if (ymax == -1) {
                ymax = Rows - 1;
                break;
            }
        }
        xstart = Math.max(0, xstart);
        ystart = Math.max(0, ystart);
        let xend = Math.min(xstart + getRandomInt(1, 4), xmax);
        let yend = Math.min(ystart + getRandomInt(2, 5), ymax);
        if (xstart == -1 || xend == -1 || ystart == -1 || yend == -1) {
            return null;
        } else {
            // fill gridGallery
            for (let r = ystart; r <= yend; r++) {
                for (let c = xstart; c <= xend; c++) {
                    galleryGrid[r][c] = 'X';
                }
            }
            return (
                <div css={css`
                    grid-column-start: ${ColStart_LuT[xstart]};
                    grid-column-end: ${ColEnd_LuT[xend]};
                    grid-row-start: ${RowStart_LuT[ystart]};
                    grid-row-end: ${RowEnd_LuT[yend]};

                    img,
                    .gatsby-image-wrapper {
                        height: 100%;
                        width: 100%;
                    }
                `}>
                    <Img fluid={item} width="100%" height="100%" imgStyle={{ transition: "all 0.5s ease" }} />
                </div>
            );
        }
    })
    return (
        <Gallery>
            {GalleryContent}
        </Gallery>
    )
}
